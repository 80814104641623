.carousel {
  position: relative;
}

.carousel-indicators li {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.carousel-indicators li:last-child {
  margin-right: 0;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
}

.carousel-inner .item {
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

.carousel-inner .item.next, .carousel-inner .item.active.right {
  transform: translateX(100%);
}

.carousel-inner .item.prev, .carousel-inner .item.active.left {
  transform: translateX(-100%);
}

.carousel-inner .item.next.left, .carousel-inner .item.prev.right, .carousel-inner .item.active {
  transform: translateX(0);
}

.carousel-inner .item.active {
  display: block;
}

.carousel-inner > .next, .carousel-inner > .prev {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.carousel-control {
  width: 10%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-control.left {
  left: 0;
}

.carousel-control.right {
  right: 0;
}

.tippy-box {
  max-width: 300px !important;
}

.tippy-content {
  color: #fff;
  padding: 9px;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

.diploma-tooltip {
  color: #fff;
  text-decoration: underline;
  transition: all .3s;
}

.diploma-tooltip:hover {
  text-decoration: none;
}

.carousel {
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
  display: -ms-flexbox;
  display: flex;
}

.carousel-inner {
  margin: 0 auto 20px;
}

.carousel-control {
  display: none;
}

.icon-tooltip {
  margin-bottom: -2px;
}

.carousel-indicators li {
  cursor: pointer;
  color: #fff;
  text-align: center;
  height: unset;
  width: 100%;
  background: #071683;
  border: 1px solid #fff45f;
  margin-right: 0;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}

.carousel-indicators li:first-child {
  margin-bottom: 10px;
}

@media (min-width: 769px) {
  .carousel-indicators li:first-child {
    border-radius: 4px 0 0 4px;
    margin-bottom: 0;
  }

  .carousel-indicators li:last-child {
    border-radius: 0 4px 4px 0;
  }

  .carousel-indicators li {
    width: 33%;
  }
}

.carousel-indicators li.active {
  color: #071683;
  background-color: #fff45f;
}

.wrap-search {
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.wrap-search form {
  width: 86%;
}

.st-course__document-info {
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 768px) {
  .st-course__document-info {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
  }
}

.search-result {
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.search-result:after {
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  top: 18px;
  bottom: 3px;
  left: 3px;
  right: 3px;
  box-shadow: 0 6px 16px 3px rgba(0, 0, 0, .3);
}

.search-result ul:first-child {
  margin-bottom: 10px;
}

.search-result ul li a {
  color: #445d74;
  width: 100%;
  padding: 4px 10px;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

.search-result ul li a:hover {
  background-color: #eee;
}

.search-result .dg-search-result__header {
  color: #101786;
  border-bottom: 1px dashed #eee;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
}

.search-result .dg-search-result__footer {
  border-top: 1px dashed #eee;
}

.search-result .dg-search-result__footer a {
  text-align: right;
  padding: 10px;
}

.search-result .dg-search-result__footer a:last-child:hover {
  border-radius: 0 0 6px 6px;
}

.search-result .dg-search-result__none {
  color: #000;
  padding: 20px;
  display: block;
}

.search-result .dg-cost {
  color: red;
}

.st-search__search-wrapper {
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 0;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .st-search__search-wrapper {
    padding: 20px 30px;
  }
}

.st-search__search-input {
  z-index: 2;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-right: 4px;
  position: relative;
}

.st-search__search-input input {
  width: 100%;
  color: #555;
  border: none;
  padding: 12px;
  font-size: 18px;
  font-weight: 300;
}

@media (max-width: 768px) {
  .st-search__search-input {
    width: 100%;
    margin-right: 0;
  }
}

.st-search__search-btn {
  cursor: pointer;
  color: #000;
  background-color: #f9fc30;
  border: none;
  padding: 11px 30px;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}

@media (max-width: 768px) {
  .st-search__search-btn {
    z-index: 2;
    padding: 11px 20px;
    position: absolute;
    right: 0;
  }

  .st-search__search-text {
    display: none;
  }
}

.st-search__search-btn-hide {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 769px) {
  .st-search__search-btn-hide {
    display: none;
  }
}

.st-section__gray {
  background-color: #f4f4f4;
  padding: 40px 0;
}

.st-section__blue {
  background-color: #060d83;
  padding: 40px 0;
}

.st-section__white {
  background-color: #fff;
  padding: 40px 0;
}

.st-section__first {
  background-color: #f4f4f4;
  padding: 40px 0 60px;
}

@media (min-width: 769px) {
  .st-section__first {
    background-color: #e8e8e8;
    background-image: url("../img/new/main/lesson-bg.jpg");
    background-position: calc(100% - 50px) 120px;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1550px) {
  .st-section__first {
    background-position: 150% bottom;
  }
}

@media (max-width: 1280px) {
  .st-section__first {
    background-position: calc(100% + 400px) bottom;
  }
}

.st-section__second {
  background: url("../img/new/main/footer-bg.jpg") bottom / cover no-repeat;
  padding: 20px 0 60px;
}

.st-main__header-bg {
  color: #fff;
  background: linear-gradient(#0e017e, #5b29be);
  margin-top: -20px;
  padding: 40px 0;
}

@media (min-width: 769px) {
  .st-main__header-bg {
    background: url("../img/new/main/head-bg.png") top no-repeat;
  }
}

.st-main__header-title {
  text-align: center;
  margin: 0 0 14px;
  font-size: 30px;
}

@media (min-width: 769px) {
  .st-main__header-title {
    font-size: 42px;
  }
}

.st-main__header-descr {
  text-align: center;
  font-size: 20px;
}

.st-main__header-sale {
  text-align: center;
  color: #fff01f;
  margin-bottom: 40px;
  font-size: 26px;
  font-weight: 700;
}

@media (min-width: 769px) {
  .st-main__header-sale {
    font-size: 34px;
  }
}

.st-main__header-triggers {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 769px) {
  .st-main__header-triggers {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.st-main__header-item {
  color: #000;
  border-radius: 10px;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  box-shadow: 0 16px 95px rgba(18, 77, 190, .35);
}

@media (min-width: 769px) {
  .st-main__header-item {
    width: 40%;
    margin-bottom: 0;
  }

  .st-main__header-item:first-child {
    margin-right: 20px;
  }
}

@media (min-width: 475px) {
  .st-main__header-item {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.st-main__header-ls {
  background-color: #fff;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-order: 2;
  order: 2;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 769px) {
  .st-main__header-ls {
    width: 64%;
  }
}

@media (min-width: 475px) {
  .st-main__header-ls {
    -ms-flex-order: 0;
    order: 0;
  }
}

.st-main__header-rs {
  text-align: center;
  background-color: #fff;
}

@media (min-width: 769px) {
  .st-main__header-rs {
    width: 36%;
  }
}

.st-main__header-rs img {
  object-fit: cover;
  max-width: 180px;
  height: 100%;
}

.st-main__header-course {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 800;
}

.st-main__header-value {
  color: #a3a3a3;
  margin-bottom: 20px;
  font-size: 20px;
  display: block;
}

.st-main__header-btn {
  color: #060d83;
  cursor: pointer;
  border: 1px solid #060d83;
  border-radius: 8px;
  margin-top: auto;
  margin-right: auto;
  padding: 8px 14px;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s ease-out;
  display: inline-block;
}

.st-main__header-btn:after {
  content: "→";
  margin-left: 10px;
  font-size: 22px;
  font-weight: 800;
  line-height: 1;
}

.st-main__header-btn:hover {
  color: #fff;
  background-color: #060d83;
}

.st-main__title {
  text-align: center;
  margin-bottom: 50px;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.1;
}

@media (min-width: 769px) {
  .st-main__title {
    font-size: 36px;
  }
}

.st-main__title.blue-bg {
  background-color: #060d83;
  padding: 10px 0;
  line-height: 1;
}

.st-main__title.yellow {
  color: #fff45f;
}

.st-main__flex {
  counter-reset: section;
  display: -ms-flexbox;
  display: flex;
}

.st-main__flex.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.st-main__flex.flex-drc {
  -ms-flex-direction: column;
  flex-direction: column;
}

.st-main__advantages {
  counter-increment: section;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddde5;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
}

@media (min-width: 769px) and (max-width: 990px) {
  .st-main__advantages {
    width: calc(50% - 10px);
  }

  .st-main__advantages:not(:nth-child(2n)) {
    margin-right: 20px;
  }
}

@media (min-width: 990px) {
  .st-main__advantages {
    width: calc(33.3% - 14px);
  }

  .st-main__advantages:not(:nth-child(3n)) {
    margin-right: 21px;
  }
}

.st-main__advantages p {
  margin-bottom: 0;
  line-height: 1.6;
}

.st-main__advantages span {
  border-bottom: 4px solid #fce630;
}

.st-main__advantages:first-child .st-main__advantages-title:before {
  width: 60px;
  height: 60px;
  background: url("../img/new/main/icon_1.png") bottom no-repeat;
}

.st-main__advantages:nth-child(2) .st-main__advantages-title:before {
  width: 60px;
  height: 60px;
  background: url("../img/new/main/icon_2.png") bottom no-repeat;
}

.st-main__advantages:nth-child(3) .st-main__advantages-title:before {
  width: 60px;
  height: 60px;
  background: url("../img/new/main/icon_3.png") bottom no-repeat;
}

.st-main__advantages:nth-child(4) .st-main__advantages-title:before {
  width: 60px;
  height: 60px;
  background: url("../img/new/main/icon_4.png") bottom no-repeat;
}

.st-main__advantages:nth-child(5) .st-main__advantages-title:before {
  width: 60px;
  height: 60px;
  background: url("../img/new/main/icon_5.png") bottom no-repeat;
}

.st-main__advantages:nth-child(6) .st-main__advantages-title:before {
  width: 60px;
  height: 60px;
  background: url("../img/new/main/icon_6.png") bottom no-repeat;
}

.st-main__advantages-title {
  min-height: 60px;
  margin-bottom: 10px;
  padding-left: 70px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  position: relative;
}

.st-main__advantages-title:before {
  content: " ";
  font-size: 30px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
}

.st-main__course-big-title {
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  display: -ms-flexbox;
  display: flex;
}

.st-main__course-big-title a {
  color: #06f;
  padding-top: 20px;
  font-size: 20px;
  text-decoration: none;
}

@media (min-width: 768px) {
  .st-main__course-big-title a {
    padding-top: 0;
  }

  .st-main__course-big-title {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 30px;
  }
}

.st-main__course {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.st-main__course:last-child {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .st-main__course {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 20px 70px 20px 30px;
  }

  .st-main__course:before {
    content: " ";
    width: 22px;
    height: 15px;
    background: url("../img/new/main/arrow.png") no-repeat;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
}

.st-main__course-info {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  .st-main__course-info {
    -ms-flex-preferred-size: 70%;
    flex-basis: 70%;
    margin-right: 30px;
  }
}

.st-main__course-sale {
  width: 50px;
  min-width: 50px;
  height: 50px;
  background-color: #fff45f;
  border-radius: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 600;
  display: -ms-flexbox;
  display: flex;
}

.st-main__course-title {
  font-size: 18px;
  font-weight: 600;
}

.st-main__course-price {
  color: #bdbdbd;
  white-space: nowrap;
  padding-top: 20px;
  font-size: 18px;
}

.st-main__course-price:not(:last-child) {
  margin-right: 30px;
}

@media (min-width: 768px) {
  .st-main__course-price {
    padding-top: 0;
  }
}

.st-main__diplom-text {
  color: #fff;
  border-left: 4px solid #fff45f;
  padding-left: 30px;
  font-size: 20px;
  line-height: 1.44;
}

.st-main__diplom-text:not(:last-child) {
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .st-main__diplom-text {
    width: 50%;
    margin: 0 auto;
  }
}

.st-main__diplom-text span {
  color: #fff45f;
}

.st-main__info-text {
  color: #b4b6da;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  display: block;
}

@media (min-width: 768px) {
  .st-main__info-text br {
    display: none;
  }
}

.st-main__info-text a {
  color: #b4b6da;
  text-decoration: underline;
  transition: all .3s;
}

.st-main__info-text a:hover {
  text-decoration: none;
}

.st-main__info-text--grey, .st-main__info-text--grey a {
  color: #76767a;
}

.st-main__diplom-img {
  text-align: center;
  margin-bottom: 60px;
}

.st-main__diplom-img img {
  box-shadow: 0 20px 70px rgba(255, 244, 95, .7);
}

.st-main__license-descr {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 600;
}

.st-main__license-text {
  font-size: 18px;
}

.st-main__license-text a {
  color: #06f;
}

.st-main__lesson {
  counter-reset: section;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 768px) {
  .st-main__lesson {
    max-width: 480px;
    margin: 0 auto;
  }
}

.st-main__lesson-big-title {
  margin-bottom: 60px;
}

.st-main__lesson-item {
  counter-increment: section;
  margin-bottom: 63px;
  padding-left: 55px;
  position: relative;
}

.st-main__lesson-item:before {
  content: counter(section);
  width: 42px;
  height: 42px;
  border: 1px solid #000;
  border-radius: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
}

.st-main__lesson-item p {
  margin-bottom: 0;
}

.st-main__lesson-title {
  margin-bottom: 14px;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
}

.st-main__lesson-title span {
  color: #101464;
  background: #f9fc30;
  border-radius: 8px;
  margin-left: 10px;
  padding: 4px 6px;
  font-size: 18px;
  line-height: 1;
  display: inline-block;
}

.st-main__info-item {
  min-height: 64px;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 14px;
  padding-left: 74px;
  font-size: 22px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.st-main__info-item:before {
  content: " ";
  width: 64px;
  height: 64px;
  background: #fff45f url("../img/new/main/check.png") center no-repeat;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.st-main__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.st-main__list-item {
  background: #fff;
  box-shadow: 0 20px 24px rgba(18, 77, 190, .09);
}

.st-main__list-item:not(:last-child) {
  margin-bottom: 10px;
}

.st-main__list-title {
  cursor: pointer;
  padding: 10px 30px 10px 10px;
  font-weight: 600;
  position: relative;
}

@media (min-width: 768px) {
  .st-main__list-title {
    padding: 30px 50px 30px 30px;
  }
}

.st-main__list-title:before {
  content: "";
  -webkit-clip-path: polygon(50% 40%, 100% 0, 100% 10%, 50% 50%, 0 10%, 0 0);
  clip-path: polygon(50% 40%, 100% 0, 100% 10%, 50% 50%, 0 10%, 0 0);
  width: 20px;
  height: 20px;
  background: #000;
  position: absolute;
  top: 40%;
  right: 10px;
}

@media (min-width: 768px) {
  .st-main__list-title:before {
    right: 20px;
  }
}

.st-main__list-title.active:before {
  transform: translateY(-50%)rotate(180deg);
}

.st-main__list-text {
  padding: 0 30px 10px 10px;
  display: none;
}

.st-main__list-text.active {
  display: block;
}

@media (min-width: 768px) {
  .st-main__list-text {
    padding: 0 50px 30px 30px;
  }
}

.st-main__list-text b.st-big {
  font-size: 18px;
}

.st-main__list-text ol {
  padding-left: 40px;
  list-style: decimal;
}

.st-main__list-text ol li:not(:last-child) {
  margin-bottom: 10px;
}

.st-main__list-text ul {
  padding-left: 40px;
  list-style: disc;
}

.st-main__list-text ul:not(:last-child), .st-main__list-text ul li:not(:last-child) {
  margin-bottom: 10px;
}

.st-message__bg {
  color: #fff;
  background-color: #060d83;
}

.st-message__wrapper {
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
  display: -ms-flexbox;
  display: flex;
}

.st-message__wrapper .st-button {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .st-message__wrapper {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.st-message__img {
  margin-right: 10px;
}

@media (max-width: 1140px) {
  .st-message__img {
    display: none;
  }
}

.st-message__text {
  margin-right: 10px;
}

@media (max-width: 1140px) {
  .st-message__text {
    display: none;
  }
}

.st-message__number {
  text-align: center;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .st-message__number {
    margin-bottom: 10px;
  }
}

/*# sourceMappingURL=main.css.map */
