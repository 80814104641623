$path-img: '../img/new/main/';
@import "modules/_carousel";
@import "modules/_tippy";

.carousel {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.carousel-inner {
  margin: 0 auto 20px;
}

.carousel-control {
  display: none;
}

.icon-tooltip {
  margin-bottom: -2px;
}

.carousel-indicators {
  & li {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #fff45f;
    background: #071683;
    font-size: 16px;
    font-weight: 500;
    color: white;
    padding: 12px 24px;
    text-align: center;
    height: unset;
    width: 100%;
    margin-right: 0;
    &:first-child {
      margin-bottom: 10px;
    }
    @media (min-width: 769px) {
      &:first-child {
        border-radius: 4px 0 0 4px;
        margin-bottom: 0;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
    @media (min-width: 769px) {
      width: 33%;
    }
    &.active {
      background-color: #fff45f;
      color: #071683;
    }
  }
}

.wrap-search {
  display: flex;
  justify-content: center;
  & form {
    width: 86%;
  }
}

.st-course__document-info {
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.search-result {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 0 0 6px 6px;
  background-color: white;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 18px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    box-shadow: 0 6px 16px 3px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  & ul {
    &:first-child {
      margin-bottom: 10px;
    }
    & li {
      & a {
        text-decoration: none;
        color: #445d74;
        display: block;
        font-size: 16px;
        width: 100%;
        padding: 4px 10px;
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  & .dg-search-result {
    &__header {
      padding: 10px;
      color: #101786;
      font-size: 20px;
      font-weight: 600;
      border-bottom: 1px dashed #eee;
    }
    &__footer {
      border-top: 1px dashed #eee;
      a {
        padding: 10px;
        text-align: right;
        &:last-child:hover {
          border-radius: 0 0 6px 6px;
        }
      }
    }
    &__none {
      display: block;
      padding: 20px;
      color: black;
    }
  }
  & .dg-cost {
    color: red;
  }
}

.st-search {
  &__search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      padding: 20px 30px;
    }
  }
  &__search-input {
    position: relative;
    z-index: 2;
    flex-grow: 1;
    margin-right: 4px;
    & input {
      width: 100%;
      border: none;
      font-weight: 300;
      color: #555;
      padding: 12px;
      font-size: 18px;
    }
    @media (max-width: 768px) {
      margin-right: 0;
      width: 100%;
    }
  }
  &__search-btn {
    cursor: pointer;
    display: inline-block;
    padding: 11px 30px;
    background-color: #f9fc30;
    color: black;
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
    border: none;
    @media (max-width: 768px) {
      position: absolute;
      right: 0;
      padding: 11px 20px;
      z-index: 2;
    }
  }
  &__search-text {
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__search-btn-hide {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 769px) {
      display: none;
    }
  }
}

.st-section {
  &__gray {
    padding: 40px 0;
    background-color: #f4f4f4;
  }
  &__blue {
    padding: 40px 0;
    background-color: #060d83;
  }
  &__white {
    padding: 40px 0;
    background-color: white;
  }
  &__first {
    background-color: #f4f4f4;
    padding: 40px 0 60px;
    @media (min-width: 769px) {
      background-image: url('#{$path-img}lesson-bg.jpg');
      background-color: #e8e8e8;
      background-position: calc(100% - 50px) 120px;
      background-repeat: no-repeat;
    }
    @media (max-width: 1550px) {
      background-position: 150% bottom;
    }
    @media (max-width: 1280px) {
      background-position: calc(100% + 400px) bottom;
    }
  }
  &__second {
    background: url('#{$path-img}footer-bg.jpg') center bottom no-repeat;
    padding: 20px 0 60px;
    background-size: cover;
  }
}

.st-main {
  &__header-bg {
    margin-top: -20px;
    padding: 40px 0;
    background: linear-gradient(#0e017e , #5b29be);
    color: white;
    @media (min-width: 769px) {
      background: url('#{$path-img}head-bg.png') center top no-repeat;
    }
  }
  &__header-title {
    margin: 0 0 14px;
    font-size: 30px;
    text-align: center;
    @media (min-width: 769px) {
      font-size: 42px;
    }
  }
  &__header-descr {
    text-align: center;
    font-size: 20px;
  }
  &__header-sale {
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    color: #fff01f;
    margin-bottom: 40px;
    @media (min-width: 769px) {
      font-size: 34px;
    }
  }
  &__header-triggers {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 769px) {
      flex-direction: row;
    }
  }
  &__header-item {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    color: black;
    box-shadow: 0 16px 95px 0 rgba(18, 77, 190, 0.35);
    margin-bottom: 20px;
    flex-direction: column;
    @media (min-width: 769px) {
      width: 40%;
      margin-bottom: 0;
      &:first-child {
        margin-right: 20px;
      }
    }
    @media (min-width: 475px) {
      flex-direction: row;
    }
  }
  &__header-ls {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    flex-grow: 1;
    order: 2;
    @media (min-width: 769px) {
      width: 64%;
    }
    @media (min-width: 475px) {
      order: 0;
    }
  }
  &__header-rs {
    background-color: white;
    text-align: center;
    @media (min-width: 769px) {
      width: 36%;
    }
    & img {
      object-fit: cover;
      max-width: 180px;
      height: 100%;
    }
  }
  &__header-course {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  &__header-value {
    font-size: 20px;
    margin-bottom: 20px;
    display: block;
    color: #a3a3a3;
  }
  &__header-btn {
    margin-top: auto;
    margin-right: auto;
    text-decoration: none;
    display: inline-block;
    padding: 8px 14px;
    color: #060d83;
    font-weight: 600;
    font-size: 20px;
    border: 1px solid #060d83;
    border-radius: 8px;
    cursor: pointer;
    transition: .2s ease-out;
    &:after {
      content: "→";
      margin-left: 10px;
      font-weight: 800;
      line-height: 1;
      font-size: 22px;
    }
    &:hover {
      background-color: #060d83;
      color: white;
    }
  }
  &__title {
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 50px;
    line-height: 1.1;
    @media (min-width: 769px) {
      font-size: 36px;
    }
    &.blue-bg {
      background-color: #060d83;
      line-height: 1;
      padding: 10px 0;
    }
    &.yellow {
      color: #fff45f;
    }
  }
  &__flex {
    display: flex;
    counter-reset: section;
    &.flex-wrap {
      flex-wrap: wrap;
    }
    &.flex-drc {
      flex-direction: column;
    }
  }
  &__advantages {
    counter-increment: section;
    width: 100%;
    border: 1px solid #dddde5;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: white;
    @media (min-width: 769px) and (max-width: 990px) {
      width: calc(50% - 10px);
      &:not(:nth-child(2n)) {
        margin-right: 20px;
      }
    }
    @media (min-width: 990px) {
      width: calc(33.3% - 14px);
      &:not(:nth-child(3n)) {
        margin-right: 21px;
      }
    }
    & p {
      margin-bottom: 0;
      line-height: 1.6;
    }
    & span {
      border-bottom: 4px solid #fce630;
    }
    @for $i from 1 to 7 {
      &:nth-child(#{$i}) {
        & .st-main__advantages-title {
          &:before {
            background: url('#{$path-img}icon_#{$i}.png') center bottom no-repeat;
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
  &__advantages-title {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
    padding-left: 70px;
    min-height: 60px;
    &:before {
      content: " ";
      position: absolute;
      left: 0px;
      top: 0px;
      font-size: 30px;
      font-weight: 600;
    }
  }
  &__course-big-title {
    display: flex;
    font-size: 24px;
    flex-direction: column;
    margin-bottom: 20px;
    font-weight: 600;
    & a {
      font-size: 20px;
      color: #0066ff;
      text-decoration: none;
      padding-top: 20px;
      @media (min-width: 768px) {
        padding-top: 0;
      }
    }
    @media (min-width: 768px) {
      flex-direction: row;
      padding: 0 30px;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__course {
    position: relative;
    padding: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: black;
    cursor: pointer;
    flex-wrap: wrap;
    &:last-child {
      margin-bottom: 40px;
    }
    @media (min-width: 768px) {
      flex-wrap: nowrap;
      padding: 20px 70px 20px 30px;
      &:before {
        position: absolute;
        content: " ";
        right: 30px;
        top: 50%;
        width: 22px;
        height: 15px;
        background: url('#{$path-img}arrow.png') no-repeat;
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__course-info {
    display: flex;
    flex-basis: 100%;
    @media (min-width: 768px) {
      flex-basis: 70%;
      margin-right: 30px;
    }
  }
  &__course-sale {
    width: 50px;
    min-width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff45f;
    border-radius: 50%;
    margin-right: 20px;
    font-weight: 600;
  }
  &__course-title {
    font-size: 18px;
    font-weight: 600;
  }
  &__course-price {
    color: #bdbdbd;
    font-size: 18px;
    padding-top: 20px;
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: 30px;
    }
    @media (min-width: 768px) {
      padding-top: 0;
    }
  }
  &__diplom-text {
    font-size: 20px;
    line-height: 1.44;
    color: white;
    padding-left: 30px;
    border-left: 4px solid #fff45f;
    &:not(:last-child) {
      margin-bottom: 60px;
    }
    @media (min-width: 768px) {
      width: 50%;
      margin: 0 auto;
    }
    & span {
      color: #fff45f;
    }
  }
  &__info-text {
    display: block;
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #b4b6da;

    br {
      @media (min-width: 768px) {
        display: none;
      }
    }

    a {
      text-decoration: underline;
      color: #b4b6da;
      transition: 0.3s;

      &:hover {
        text-decoration: none;
      }
    }

    &--grey {
      color: #76767a;

      a {
        color: #76767a;
      }
    }
  }
  &__diplom-img {
    text-align: center;
    margin-bottom: 60px;
    & img {
      box-shadow: 0 20px 70px rgba(255, 244, 95, 0.7);
    }
  }
  &__license-descr {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  &__license-text {
    font-size: 18px;
    & a {
      color: #0066ff;
    }
  }
  &__lesson {
    padding: 0;
    margin: 0;
    list-style-type: none;
    counter-reset: section;
    @media (max-width: 768px) {
      max-width: 480px;
      margin: 0 auto;
    }
  }
  &__lesson-big-title {
    margin-bottom: 60px;
  }
  &__lesson-item {
    padding-left: 55px;
    counter-increment: section;
    position: relative;
    margin-bottom: 63px;
    &:before {
      position: absolute;
      content: counter(section);
      left: 0;
      width: 42px;
      height: 42px;
      border: 1px solid black;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
    }
    & p {
      margin-bottom: 0;
    }
  }
  &__lesson-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 14px;
    line-height: 1.2;
    & span {
      color: #101464;
      display: inline-block;
      background: #f9fc30;
      padding: 4px 6px;
      line-height: 1;
      margin-left: 10px;
      font-size: 18px;
      border-radius: 8px;
    }
  }
  &__info-item {
    display: flex;
    align-items: center;
    padding-left: 74px;
    position: relative;
    min-height: 64px;
    margin-bottom: 14px;
    font-size: 22px;
    &:before {
      position: absolute;
      content: " ";
      left: 0;
      top: 0;
      width: 64px;
      height: 64px;
      background: #fff45f url('#{$path-img}check.png') center center no-repeat;
      border-radius: 50%;
    }
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__list-item {
    box-shadow: 0 20px 24px 0 rgba(18, 77, 190, 0.09);
    background: white;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__list-title {
    cursor: pointer;
    padding: 10px 30px 10px 10px;
    position: relative;
    font-weight: 600;
    @media (min-width: 768px) {
      padding: 30px 50px 30px 30px;
    }
    &:before {
      content: "";
      position: absolute;
      right: 10px;
      top: 40%;
      -webkit-clip-path: polygon(50% 40%, 100% 0, 100% 10%, 50% 50%, 0 10%, 0 0);
      clip-path: polygon(50% 40%, 100% 0, 100% 10%, 50% 50%, 0 10%, 0 0);
      width: 20px;
      height: 20px;
      background: black;
      @media (min-width: 768px) {
        right: 20px;
      }
    }
    &.active {
      &:before {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &__list-text {
    display: none;
    padding: 0 30px 10px 10px;
    &.active {
      display: block;
    }
    @media (min-width: 768px) {
      padding: 0 50px 30px 30px;
    }
    & b.st-big {
      font-size: 18px;
    }
    & ol {
      padding-left: 40px;
      list-style: decimal;
      & li {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    & ul {
      padding-left: 40px;
      list-style: disc;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      & li {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.st-message {
 &__bg {
   background-color: #060d83;
   color: white;
 }
  &__wrapper {
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    & .st-button {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__img {
    margin-right: 10px;
    @media (max-width: 1140px) {
      display: none;
    }
  }
  &__text {
    margin-right: 10px;
    @media (max-width: 1140px) {
      display: none;
    }
  }
  &__number {
    font-size: 22px;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
}