.carousel {
  position: relative;
}

.carousel-indicators {
  & li {
    display: inline-block;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
  }
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  & .item {
    display: none;
    position: relative;
    transition: transform 0.6s ease-in-out;
    &.next,
    &.active.right {
      transform: translateX(100%);
    }
    &.prev,
    &.active.left {
      transform: translateX(-100%);
    }
    &.next.left,
    &.prev.right,
    &.active {
      transform: translateX(0);
    }
    &.active {
      display: block;
    }
  }
  > .next,
  > .prev {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}