.tippy-box {
  max-width: 300px !important;
}

.tippy-content {
  padding: 9px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #fff;
}

.diploma-tooltip {
  text-decoration: underline;
  color: #fff;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
  }
}